<template>
  <div class="wrap-content">
    <div class="header">
      <h2>数据目录填报</h2>
      <ul></ul>
      <div class="button">
        <CommonHeader />
      </div>
    </div>
    <div class="main">
      <div class="card">
        <div class="logo">
          <img src="../../assets/img/成功.png" alt="" />
        </div>
        <h2>{{ msg }}</h2>
        <p v-if="!isShow">
          重要数据目录<span class="link">{{ obj.important }}</span>
          条，核心数据目录<span class="link">{{ obj.core }}</span> 条
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>
  
  <script setup>
import CommonHeader from "@/components/commonHeader.vue";
import Footer from "@/components/footer.vue";
import { Modal, Message } from "view-ui-plus";
import { useRouter } from "vue-router";
import { reactive, ref, onMounted, inject } from "vue";

let axios = inject("axios");

let router = useRouter();
let isHover = ref(false);
const handleBack = () => {
  router.back();
};

let isShow = ref(true);
let msg = ref("");
let obj = ref({
  important: 0,
  core: 0,
});
const validatePath = () => {
  let currentYear = new Date().getFullYear();

  let url = `/message/content/check?year=${currentYear}`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      if (!data.isOpenFill) {
        isShow.value = true;
        msg.value = "数据目录填报功能已关闭";
      }
      if (data.isDeclare || data.isCompleted) {
        isShow.value = false;
        msg.value = "已完成2023年度数据目录填报";
      }
    }
  });
};
onMounted(() => {
  validatePath();
});
</script>
  
  <style lang="scss" scoped>
.wrap-content {
  padding: 0 40px;
  background: #fff;
  min-height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 15px 0;
  height: 70px;
  > h2 {
    font-family: PingFangSC-Medium;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0px;
    color: #111111;
  }
}
.button {
  display: flex;
  align-items: center;

  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}

.main {
  min-height: calc(100vh - 110px);
  display: flex;
  justify-content: center;
  align-content: center;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    width: 201px;
    height: 200px;
    border-radius: 20px;
    background-image: linear-gradient(#47d47f, #47d47f),
      linear-gradient(#f9f9f9, #f9f9f9);
    background-blend-mode: normal, normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h2 {
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    // line-height: 20px;
    color: #111111;
    // line-height: 33px;
    // margin-bottom: 60px;
    text-align: center;
    margin: 30px 0;
  }

  > p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #555555;
    text-align: center;
    .link {
      color: #3d55a8;
    }
    .link:hover {
      cursor: pointer;
      //   text-decoration: underline;
    }
  }
}
.link {
  color: #3d55a8;
  padding: 0 3px;
}
</style>